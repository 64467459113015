<template>
  <div class="login login-4 login-signin-on d-flex flex-row-fluid" id="kt_login">
    <div
      class="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat"
      style="background-image: url('assets/media/bg/bg-3.jpg')"
    >
      <div class="login-form text-center p-7 position-relative overflow-hidden">
        <!--begin::Login Header-->
        <div class="d-flex flex-center mb-15">
          <a href="#">
            <Logo class="max-h-150px" />
          </a>
        </div>
        <!--end::Login Header-->
        <!--begin::Login Sign in form-->
        <div class="login-signin">
          <div class="mb-20">
            <h3 class="display-3">Регистрация</h3>
            <div class="text-muted font-weight-bold">Введите данные от аккаунта:</div>
          </div>
          <form class="form" id="kt_login_signin_form" @submit.prevent="onSubmit">
            <div class="form-group mb-5">
              <input
                class="form-control h-auto form-control-solid py-4 px-8"
                type="text"
                placeholder="Логин"
                name="username"
                v-model="username"
                autocomplete="off"
              />
            </div>
            <div class="form-group mb-5">
              <input
                class="form-control h-auto form-control-solid py-4 px-8"
                type="password"
                placeholder="Пароль"
                v-model="password"
                name="password"
              />
            </div>
            <button class="btn btn-success font-weight-bold px-9 py-4 my-3 mx-4">
              Регистрация
            </button>
          </form>
          <div class="mt-10">
            <span class="opacity-70 mr-4">Уже есть аккаунт?</span>
            <router-link
              :to="{ name: 'login' }"
              class="text-muted text-hover-primary font-weight-bold"
            >
              Вход по паролю
            </router-link>
          </div>
        </div>
        <!--end::Login Sign in form-->
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import api from "@/core/api";

import Logo from "@/assets/icons/logo.svg";

const userDataMock = {
  data: {
    // name: String,
    // second_name: String,
    // last_name: String,
    // address: String,
    // phone: String,
    // email: String,
    role: [
      {
        name: "base",
        sub_role: [],
      },
    ],
  },
};

export default {
  components: { Logo },
  setup() {
    const router = useRouter();
    const username = ref("");
    const password = ref("");

    const onSubmit = async () => {
      const userData = Object.assign({}, userDataMock);
      userData.login = username.value;
      userData.password = password.value;

      try {
        await api.createUser(userData);
        router.push({ name: "login" });
      } catch (e) {
        console.log(e);
      }
    };

    return {
      username,
      password,
      onSubmit,
    };
  },
};
</script>

<style scoped lang="scss" src="./Registration.scss"></style>
